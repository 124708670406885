var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "div",
        { staticClass: "search-toolbar-wrapper" },
        [_c("search-toolbar", { on: { search: _vm.search } })],
        1
      ),
      _c(
        "ejs-grid",
        {
          ref: "employees-grid",
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            sortSettings: _vm.sortOptions,
            filterSettings: _vm.filterOptions,
            toolbar: _vm.toolbarOptions,
            searchSettings: _vm.searchOptions,
            pageSettings: _vm.pageSettings,
            editSettings: _vm.editSettings,
            toolbarClick: _vm.toolbarClick,
            commandClick: _vm.commandClick,
            childGrid: _vm.childGrid,
            detailDataBound: _vm.onDetailDataBound
          },
          on: {
            actionComplete: _vm.actionComplete,
            actionFailure: _vm.actionFailure
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Name",
                  headerText: _vm.$t("shared.name"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Surname",
                  headerText: _vm.$t("shared.surname"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EmployeeRoleName",
                  headerText: _vm.$t("shared.role"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "FiscalCode",
                  headerText: _vm.$t("shared.fiscalCode"),
                  filter: _vm.textBoxFilter,
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "VatNumber",
                  headerText: _vm.$t("shared.vatNumber"),
                  width: "200",
                  filter: _vm.textBoxFilter,
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "PhoneNumber",
                  headerText: _vm.$t("shared.phoneNumber"),
                  width: "150",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "MobileNumber",
                  headerText: _vm.$t("shared.mobileNumber"),
                  width: "150",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Email",
                  headerText: _vm.$t("shared.email"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "EmploymentDate",
                  headerText: _vm.$t("employees.employmentDate"),
                  width: "130",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "TerminationDate",
                  headerText: _vm.$t("employees.terminationDate"),
                  width: "130",
                  type: "date",
                  format: "yMd"
                }
              }),
              _c("e-column", {
                attrs: { width: "210", commands: _vm.commands }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }